import { render, staticRenderFns } from "./CategoryTree.vue?vue&type=template&id=264f1cfb&scoped=true"
import script from "./CategoryTree.vue?vue&type=script&lang=ts"
export * from "./CategoryTree.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "264f1cfb",
  null
  
)

export default component.exports